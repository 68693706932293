import React from 'react';
import './ComingSoon.css';

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <div className="coming-soon-content">
        <h1>Coming Soon</h1>
        <p>  Something amazing is on the way! Our new website is under construction, and we can't wait to share it with you. Stay tuned!</p>
      </div>
    </div>
  );
};

export default ComingSoon;
