import "bootstrap/dist/css/bootstrap.css";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import "./css/Common.css";
import "./css/Theme.css";
import React from "react";
import ReactDOM from "react-dom/client";
// import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ComingSoonComponent from "./pages/ComingSoon/ComingSoonComponent";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<ComingSoonComponent />);
